import React from 'react'

import {Footer, SignupDivider, TextPage} from '../../components';
import {WebLayout} from '../../layouts';

const Terms = () => (
  <WebLayout>
    <TextPage title="Terms of use">
      
      <h3>Duuers Oy – Terms of Service</h3>
      <p>Last updated on 24 November 2017</p>
      <h6>Scope of these Terms of Service and Acceptance</h6>
      
      <p>
        These terms of service (“Terms of Service”) govern your (“You”) use of the Duuers applications, website and services, made available to You by Duuers Oy, Finnish Business Identity Code 2802294-9 (“Company”) (hereinafter collectively the “Service”).
      </p>
      <p>
        By accessing the Service and/or registering an account for use in the Service and/or using the Service, You accept and undertake to be bound by these Terms of Service. If You do not accept these Terms of Service, do not proceed with the registration and do not access and/or use the Service.
      </p>
      <p>
        The terms and conditions related to privacy and protection of Your personal information are governed by Duuers Oy’s Privacy Policy (<a href="https://www.duuers.com/privacy/">https://www.duuers.com/privacy/</a>) provided in the Service (“Privacy Policy”).
      </p>
      <h6>Your Access to the Service</h6>
      <p>
      Subject to Your acceptance of and compliance with these Terms of Service, Duuers Oy grants You a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to use the Service for personal purposes for the term of Your right to use the Service.
      </p>
      <p>
      The Service is available only to individuals who are at least of legal age in their respective jurisdiction.
      </p>
      <p>
      Except as specifically allowed pursuant to these Terms of Service, You are not entitled to use, copy, store, modify, transfer, distribute or otherwise make publicly available Your account, the Service, a part thereof or the material contained therein in any way. Your use of the Service is subject to these Terms of Service and any reasonable instructions provided by Duuers Oy from time to time.
      </p>
      <p>
      You warrant that the information You provide or provided in connection with the registration to and use of the Services (such as Your contact information) is true and accurate.
      </p>
      <p>
      You must keep the password required for the use of the Service secret and not disclose it to anyone else. You may not assign or transfer Your User ID to a third party and You may not allow a third party to use the Service with Your User ID. If a third party has obtained Your password or You have a reason to believe that a third party has obtained Your password, You must immediately inform Duuers Oy. You are responsible for actions taken by using Your User ID until You have informed Duuers Oy of the loss of the password and Duuers Oy has had a reasonable time to prevent the use of the Service with the User ID. 
      </p>
      <p>
      Duuers Oy reserves the right to terminate or restrict Your access to the Service without prior notice if You violate these Terms of Service or if You abuse the Service. Duuers Oy is entitled to prevent access to the Service if it has reason to believe that You are engaged in illegal activity or compromise other users' or 
      Duuers Oy's data security or privacy, or if Duuers Oy receives a notice that Your password has gotten into the hands of a third party.
      </p>
      <p>
      The Company may terminate Your access to and use of the Services if, under appropriate circumstances, you are determined to be a repeat infringer of the copyrights or other intellectual property rights of the Company or third parties. In the case of such termination, the Company will have no obligation to provide a refund of any amounts previously paid to the Company by You.
      </p>
      <p>
      No devices or connections necessary for the use of the Service are provided subject to these Terms of Service. You are responsible for purchasing, maintaining and updating such devices and connections (including data security) and for any costs related thereto.
      </p>
      <h6>Intellectual Property Rights</h6>
      <p>
      Duuers Oy (or its licensors) shall own all rights, title and interest in and to the Service, including any copyright, patent, trademark, design right, trade secret and any other intellectual property rights. You shall not receive any ownership rights by using the Service.
      </p>
      <p>
      All rights, title and interest in and to the material uploaded to the Service by You shall be continued to be owned by You and/or third parties. By uploading the said material to the Service, You i) assure that You are either the legitimate owner of such intellectual property or have the legitimate right given by the third party owner to upload such protected material to the Service and ii) grant the Company a limited, non-exclusive and royalty-free right to use the material in connection with the Service and its provision.
      </p>
      <p>
      The right to use Your name and trademarks as a public reference for the Service shall be agreed upon separately in writing.
      </p>
      <h6>Intellectual Property Infringements</h6>
      <p>
      As Duuers Oy asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If You believe that material located on or linked to by Services violates Your copyright, You are encouraged to notify the Company by writing to support@duuers.com. The Company will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. 
      </p>
      <h6>Pricing and Payments</h6>
      <p>
      The Service may contain certain elements that are subject to a fee. Such fees are collected and the payment traffic operated by third party payment service providers, and such payments are subject to the terms and conditions set forth by these parties. Any payments made by You in advance are non-refundable and non-transferable except as provided by the payment service providers or other third party payment operators under their own terms, in which case Duuers Oy may not be held responsible for any such terms. The prices in force from time to time are specified in the Service and/or on the Service website. The prices shall include all public charges determined by the authorities, including value added tax pursuant to the legislation in force from time to time.
      </p>
      <h6>Use of the Service </h6>
      <p>
      You agree not to use the Service in a manner or otherwise submit any material that a) violates any Intellectual Property Rights, privacy, publicity or any other rights of others; or b) would be obscene, indecent, discriminatory, abusive, defamatory or illegal, or violate good manner. Further, You c) agree not to submit any material to the Service that reveals the racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union membership or concerns the health or sex life of an identifiable individual as set forth in the applicable data protection legislation in force from time to time. You may not d) use inappropriate language or offensive expressions while using the Service. You also agree to e) use the Service in a manner that does not cause harm to Duuers Oy, other users or third parties. 
      </p>
      <p>
      You especially agree to refrain from using the Service to send messages, marketing material or proposals to persons that have not consented to such communication in advance or have not disclosed their contact information for such a use.
      </p>
      <p>
      If Duuers Oy receives a notice claiming a violation of any of the abovementioned requirements by You, Duuers Oy is entitled to terminate or restrict your access to the Service, remove the content in question from the Service or prevent Your use of the Service without notice.
      </p>
      <h6>Proposals and Agreements</h6>
      <p>
      The Service enables You to send construction proposals to other users of the Service as well as third parties, and to receive such proposals from other users. Any legal relationship or agreement being born out of or resulting from the mutual acceptance of such an proposal will be deemed to exist between You and the other user of the Service, and Duuers Oy shall not be in any way bound to the agreement or become a party to it. Any and all responsibilities arising of the agreements made based on the proposal shall be fully fulfilled and any claims responded to solely by the parties to the agreement.
      </p>
      <p>
      It is the Company’s understanding that all proposals accepted through its Service constitute a valid and binding contractual relationship under existing law. It is, however, Your and Your counterparty’s responsibility to ensure that your agreement is made in a legally binding manner, and Duuers Oy shall not be liable for the enforceability of the agreements created through its Service.
      </p>
      <h6>Links to Third Party Sites</h6>
      <p>
      The Service may contain links (both textual and graphic) to websites which are owned or operated by third parties (“Third Party Sites”). Duuers Oy shall not be responsible for the content or for products or services offered by third parties. Further, the content on Third Party Sites may be subject to separate terms of use and/or privacy policies, the contents of which Duuers Oy recommends You to review.
      </p>
      <h6>Provision of the Service</h6>
      <p>
      Duuers Oy has the right to provide the Service as it deems appropriate including the right to amend the Service. If such changes necessitate changes in Your operating environment or other devices, You shall make such changes at Your own expense. Duuers Oy aims to notify You of any substantial changes that materially affect the use of the Service a reasonable time in advance. 
      </p>
      <p>
      Duuers Oy may suspend the Service where necessary for example for installation, amendment or maintenance work or if laws, regulations or authorities so require or if there are other justifiable reasons for suspension. Duuers Oy aims to ensure that the suspension is as short as possible. Duuers Oy has the right to terminate the Service at its sole discretion. Duuers Oy aims to notify a reasonable time in advance about any suspensions or termination of the Service. 
      </p>
      <h6>Amendments and Termination</h6>
      <p>
      Duuers Oy may one-sidedly amend these Terms of Service. It remains Your responsibility to check these Terms of Service periodically for changes. Your continued use of or access to the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes. Duuers Oy may also, in the future, offer new features and/or services through the Service (including the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of these Terms of Service, and/or the terms and conditions of third parties in case those new features are offered by such third parties.
      </p>
      <p>
      You are entitled to terminate Your account for convenience with immediate effect by using the account deletion method provided in the Service or by writing to support@duuers.com and making a request for the deletion of Your account. Duuers Oy may terminate Your account for convenience with immediate effect.
      </p>
      <p>
      If, at any time, any provision hereof is or becomes illegal, invalid or unenforceable in any respect under the law of any jurisdiction, the legality, validity or enforceability of the remaining provisions shall in no way be affected or impaired thereby. The invalid provision shall be replaced by a valid one which achieves the original purpose and commercial goal of the invalid provision to the extent possible. 
      </p>
      <h6>Limitations of Liability</h6>
      <p>
      THE SERVICE IS PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND. DUUERS OY DOES NOT WARRANT THAT THE SERVICE WILL FUNCTION WITHOUT INTERRUPTIONS OR ERROR-FREE. FURTHER, DUUERS OY SHALL NOT BE LIABLE FOR THE CONTENT IN THE SERVICE, INCLUDING WITHOUT LIMITATION THE DATA, OR ANY OTHER INFORMATION EXCHANGE MEDIA ON OR CONNECTED WITH THE SERVICE. IN NO EVENT WILL DUUERS BE LIABLE TO YOU FOR ANY LOST PROFITS, LOST SAVINGS OR INCIDENTAL, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES, ARISING OUT OF YOUR USE OR INABILITY TO USE THE SERVICE OR THE BREACH OF THIS AGREEMENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
      </p>
      <p>
      Duuers Oy shall not be liable for direct or indirect damages caused by a possible delay, a change or loss of a service, product or material transferred through the Service. Duuers Oy is not liable for direct or indirect damages caused by interruptions and disturbances including loss or delay of data or changes in data due to technical defects or maintenance. Further, Duuers Oy disclaims any liability for direct or indirect damages caused to You by harmful programs (viruses, worms or the like) or incorrect content in the Service. Duuers Oy bears no liability for damages caused by You or by a third party.
      </p>
      <p>
      Duuers Oy’s maximum liability for direct damages caused to You or Your property is at all times limited to the to the amount paid by You directly to the Company for the use of the Service or part of it during the twelve months immediately preceding the event that gave reasons for the claim, notwithstanding any payments made to other users of the Service. Duuers Oy is never liable for any indirect or unforeseeable damages caused to You at any given circumstances.
      </p>
      <h6>Indemnification</h6>
      <p>
      You shall be liable and agree to indemnify Duuers Oy and third parties for and against all damages, which are caused by unlawful behavior or Your breach of these Terms of Service.
      </p>
      <h6>Translated Versions</h6>
      <p>
      These Terms of Service were originally written in English. We may translate these Terms into other languages. In the event of a conflict between the wording of a translated version of these Terms of Service and the original English version, the wording of the English version will prevail.
      </p>
      <h6>Applicable Law and Dispute Resolution</h6>
      <p>
      These Terms of Service and the contractual relation related thereto shall be governed by the laws of Finland without reference to its choice of law rules. Disputes arising out of these Terms of Service or the contractual relation related thereto will be attempted to be settled amicably. If no agreement is attained, the dispute shall be settled at the district court of Helsinki, Finland.
      </p>
      <h6>Further Information</h6>
      <p>
      Please contact us at support@duuers.com if you have any questions about our terms of service.
      </p>
      Duuers Oy 
      <br/>
      Innopoli 3
      <br/>
      Vaisalantie 8
      <br/>
      FI-02130 Espoo
      <br/>
      Finland
      
      
      
    </TextPage>
    <SignupDivider/>
    <Footer/>
  </WebLayout>
)

export default Terms
